exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dao-tsx": () => import("./../../../src/pages/dao.tsx" /* webpackChunkName: "component---src-pages-dao-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-goodbye-tsx": () => import("./../../../src/pages/goodbye.tsx" /* webpackChunkName: "component---src-pages-goodbye-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifesto-discord-login-tsx": () => import("./../../../src/pages/manifesto-discord-login.tsx" /* webpackChunkName: "component---src-pages-manifesto-discord-login-tsx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-web-3-pledge-tsx": () => import("./../../../src/pages/web3pledge.tsx" /* webpackChunkName: "component---src-pages-web-3-pledge-tsx" */)
}

